<template>
    <div class="input" :class="{ 'input--error': error }">
        <label :for="id" class="equal-small-1">{{ label }}</label>
        <span v-if="optional" class="equal-small-3 input__optional">
            {{ t('form.input.optional') }}
        </span>
        <div class="input__wrapper">
            <input
                ref="el"
                :id="id"
                :type="inputType.type"
                :placeholder="placeholder"
                :maxlength="maxlength"
                class="equal-base equal-base--tighter input__input"
                :class="{ 'input__input--button': button }"
                :value="modelValue"
                :required="required"
                @input="$emit('update:modelValue', $event.target.value)"
            />
            <button
                type="button"
                class="input__button"
                v-if="button"
                @click="visibilityToggle"
            >
                <IconsWrapper size="22px">
                    <IconsVisible v-if="visibility" class="input__visible" />
                    <IconsHidden v-else />
                </IconsWrapper>
            </button>
        </div>
        <div v-if="error" class="equal-small-3 input__error">
            {{ error }}
        </div>
        <ul v-if="requirements?.length" class="input__requirements">
            <li
                v-for="item in requirements"
                :key="item"
                class="equal-small-4 input__requirement"
                :class="{ 'input__requirement--success': item.icon }"
            >
                <IconsCheck v-if="item.icon" />
                <IconsClose v-if="!item.icon" />
                {{ item.message }}
            </li>
        </ul>
    </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    modelValue: {
        type: String,
        default: '',
    },
    id: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: '<LABEL>',
        required: true,
    },
    placeholder: {
        type: String,
        default: '<PLACEHOLDER>',
    },
    optional: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: 'text',
    },
    maxlength: {
        type: Number,
    },
    visible: {
        type: Boolean,
        default: true,
    },
    button: {
        type: Boolean,
        default: false,
    },
    requirements: {
        type: Object,
    },
    error: {
        type: String,
        default: null,
    },
    required: {
        type: Boolean,
        default: false,
    },
});

var visibility = ref(props.visible);
const inputType = reactive({
    type: props.visible == true ? visibilityType() : 'password',
});

function visibilityToggle() {
    visibility.value = !visibility.value;

    if (visibility.value) {
        inputType.type = visibilityType();
    } else {
        inputType.type = 'password';
    }
}

function visibilityType() {
    if (props.type == 'password') {
        return 'text';
    } else {
        return props.type;
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.input {
    &--error {
        .input {
            &__input {
                color: #ee2323;
                border-color: #ee2323;
                background-color: #190400;

                &::placeholder {
                    color: #9d1a00;
                }

                &:hover,
                &:focus,
                &:active {
                    border-color: #fc5534;
                }
            }

            &__button {
                :deep(svg) {
                    fill: #ee2323;
                }

                &:hover,
                &:focus,
                &:active {
                    :deep(svg) {
                        fill: #ee2323;
                    }
                }
            }
        }
    }

    label {
        margin-bottom: 5px;
    }

    &__optional {
        margin-left: 10px;
        color: $color-dark-disabled;
    }

    &__wrapper {
        position: relative;
    }

    &__input {
        height: 50px;
        width: 100%;

        border: 1px solid $color-dark-disabled;
        background-color: unset;
        border-radius: 10px;

        padding: 14px 19px 14px 19px;

        outline: none;
        box-shadow: unset;

        &::placeholder {
            color: $color-dark-disabled;
        }
    }

    &__input--button {
        padding-right: 52px;
    }

    &__button {
        position: absolute;
        display: block;
        right: 0;
        bottom: 0;
        height: 50px;
        width: auto;
        border: 0;
        margin: 0 20px 0 0;
        padding: 0;
        background-color: unset;

        :deep(svg) {
            fill: #999999;
        }

        &:hover,
        &:focus,
        &:active {
            :deep(svg) {
                fill: $color-light;
            }
        }
    }

    &__visible {
        :deep(svg) {
            fill: $color-light;
        }
    }

    &__requirements {
        list-style: none;
        padding: 0;
        margin-top: 9px;
        margin-bottom: 0;
    }

    &__requirement {
        display: flex;
        margin-bottom: 6px;

        :deep(svg) {
            height: 10px;
            width: 10px;
            fill: $color-light;
            margin: 1px 4px 1px 0;
        }

        &--success {
            color: $color-dark-disabled;

            :deep(svg) {
                fill: $color-dark-disabled;
            }
        }
    }

    &__error {
        margin-top: 10px;
        color: #ee2323;
    }
}
</style>
