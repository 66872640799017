<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
        <path
            d="M3.52596364 3 26.0914182 25.3957895 24.4750545 27 1.90832727 4.60421053 3.52596364 3Zm1.03421818 5.83945263 3.63109091 3.60378947C7.84 13.2264 7.63636364 14.0878737 7.63636364 14.9998737c0 3.4863158 2.85090906 6.3157895 6.36363636 6.3157895.9189091 0 1.7869091-.2021053 2.576-.5507369L19.3301818 23.4984c-1.6570909.6252632-3.4503636.9751579-5.3301818.9751579-6.36363636 0-11.79818182-3.9284211-14-9.4736842.96472727-2.4290526 2.54927273-4.5473684 4.56018182-6.16042107ZM14 5.52618947c6.3636364 0 11.7994545 3.92842106 14 9.47368423-.9647273 2.4290526-2.548 4.5473684-4.5589091 6.160421l-3.6323636-3.6037894c.3512727-.7831579.5549091-1.6446316.5549091-2.5566316 0-3.4863158-2.8509091-6.31578949-6.3636364-6.31578949-.9189091 0-1.7869091.20210526-2.576.55073684L8.67109091 6.50134737C10.3269091 5.87608421 12.1214545 5.52618947 14 5.52618947Zm-3.7647273 8.94568423L14.532 18.7362947c-.1756364.0252632-.3487273.0530527-.532.0530527-2.1127273 0-3.8181818-1.6926316-3.8181818-3.7894737 0-.1806316.028-.3536842.0534545-.528ZM14 11.2104c2.1127273 0 3.8181818 1.6926316 3.8181818 3.7894737 0 .1806316-.028.3536842-.0534545.528L13.468 11.2634526c.1756364-.0252631.35-.0530526.532-.0530526Z"
        />
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
