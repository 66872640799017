<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 22 15"
    >
        <defs>
            <path
                id="a"
                d="M11 12.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5M11 0c5 0 9.271 3.11 11 7.5-1.729 4.39-6 7.5-11 7.5S1.73 11.89 0 7.5C1.73 3.11 6 0 11 0Zm0 4.5c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3Z"
            />
        </defs>
        <use xlink:href="#a" fill-rule="evenodd" />
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
